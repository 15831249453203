<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`Wachtwoord vergeten`" :intro="``">
        <v-container>
            <v-row>
                <v-col cols="12" class="py-0" v-if="!isRequestSent">
                    <v-form ref="form" class="ma-2"
                            lazy-validation  
                            @submit.prevent="submit"
                            onSubmit="return false;">
                        <v-text-field 
                            v-model="email"
                            :disabled="isSubmitting"
                            :rules="[rules.email]"
                            label="E-mailadres"
                            @keydown.native.enter="onForgetPassword()"
                            required></v-text-field>

                        <v-btn color="primary" block @click="onForgetPassword()" class="mt-5 mb-5" :loading="isSubmitting">
                            Vraag nieuw wachtwoord aan
                        </v-btn>
                    </v-form>
                   
                </v-col>
                <v-col cols="12" v-if="isRequestSent">
                    <div>
                        <p v-if="isSuccessful" class="px-2" >Aanvraag is verstuurd. Check uw inbox.</p>
                    </div >
                </v-col>
            </v-row>
        </v-container>
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate';
import DataMixin from "@/mixins/data.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'ForgotPassword',
    components: 
    {
        BannerTemplate
    },
    mixins: [DataMixin, MetaTagsMixin],
    data: () => ({
        bannerSource: require('@/assets/album/Login/login-01.jpg'),
        isRequestSent: false,
        email: '',
        isSubmitting: false,
        isSuccessful: false,
        submit: false
    }),
    mounted()
    {
        this.$store.commit('setFooterVisibility', true);
    },
    metaInfo() {
        return {
        title: '| Wachtwoord vergeten',
        meta: [
            this.getKeywords(`Wachtwoord vergeten`),
            this.getMetaDescription(`Roadpursuit Wachtwoord vergeten`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Roadpursuit Wachtwoord vergeten`, `Roadpursuit Wachtwoord vergeten`, `${window.location.origin}${this.bannerSource}`, 'Wachtwoord vergeten'),
        ]
        };
    },
    methods: 
    {
        onForgetPassword()
        {
            const valid = this.$refs.form.validate()
            if (valid)
            {
                this.$store.dispatch('accountModule/getPasswordChangeLink', this.email)
                .then((data) => {
                    this.isSuccessful = data;
                    this.isRequestSent = true;
                })
                .catch((error) => {
                    this.isSuccessful = false;
                    this.isRequestSent = false;
                    this.isSubmitting = false;
                    this.$store.commit('dialog/showDialog', error);
                })
               
            }
        }
    }
}
</script>
